<template>
  <div>
    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Setup your login</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-form-group id="example-input-group-3" label label-for="example-input-3">
            <b-form-input
              id="example-input-3"
              name="example-input-3"
              v-model="$v.form.username.$model"
              :state="validateState('username')"
              aria-describedby="input-3-live-feedback"
              placeholder="Username"
            ></b-form-input>

            <b-form-invalid-feedback
              v-if="$v.form.username.isUnique && !$v.form.username.required"
            >Username is required.</b-form-invalid-feedback>

            <b-form-invalid-feedback v-if="!$v.form.username.isUnique">Username already taken.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="example-input-group-5" label label-for="example-input-5">
            <b-form-input
              type="password"
              id="example-input-5"
              name="example-input-5"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-5-live-feedback"
              placeholder="Password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6-live-feedback">Password is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="example-input-group-6" label label-for="example-input-6">
            <b-form-input
              type="password"
              id="example-input-6"
              name="example-input-6"
              v-model="$v.form.confirmPassword.$model"
              :state="validateState('confirmPassword')"
              aria-describedby="input-6-live-feedback"
              placeholder="Confirm Password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6-live-feedback">Password should match .</b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <a href="#" class="kt-link kt-login__link-forgot"></a>

            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right"
            >Continue</b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
      <vue-recaptcha
        ref="invisibleRecaptcha"
        :sitekey="sitekey"
        :loadRecaptchaScript="true"
        size="invisible"
      ></vue-recaptcha>
    </div>
    <vue-snotify></vue-snotify>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/store/auth.module";
import { LOGOUT, SET_LOGIN } from "@/store/auth.module";
import ApiService from "@/common/api.service";
import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
/* import VueSocialauth from "vue-social-auth"; */
import { isNullableMobileNumber } from "@/common/validation";
export default {
  components: { VueRecaptcha },
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
      form: {
        fname: "",
        lname: "",
        username: "",
        email: "",
        mobile: "",
        password: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
        async isUnique(value) {
          if (value === "") return true;

          return new Promise((resolve, reject) => {
            setTimeout(() => {
              ApiService.post("user/validate/username", {
                username: this.$v.form.username.$model
              }).then(response => {
                resolve(response.data);
              });
            }, 500);
          });
        }
      },
      password: {
        required,
        minLength: minLength(3)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        password: null
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add("kt-spinner");
      setTimeout(() => {
        submitButton.classList.remove("kt-spinner");
      }, 2000);
      ApiService.post("user/setup-login", {
        user_name: username,
        password: password
      }).then(response => {
        this.$store.getters.currentUser.is_first_social_login = false;
        this.$snotify.success(response.data.success, {
          position: "rightTop",
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });
        this.$router.push({ name: "dashboard" });
      });

      // dummy delay
    },
    AuthProvider(provider) {
      var self = this;
      this.$auth
        .authenticate(provider)
        .then(response => {
          self.SocialLogin(provider, response);
        })
        .catch(err => {
        });
    },

    SocialLogin(provider, response) {
      ApiService.get("/sanctum/csrf-cookie").then(() => {
        this.$http
          .post("/sociallogin/" + provider, response)
          .then(response => {
            this.$store
              .dispatch(SET_LOGIN, response.data)
              .then(() => this.$router.push({ name: "dashboard" }));
          })
          .catch(err => {
          });
      });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-2.jpg";
    }
  },
  mounted: function() {}
};
</script>
